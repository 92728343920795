import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "privacy-article"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvalidUrl = _resolveComponent("InvalidUrl")!

  return (_ctx.$route.path === '/404')
    ? (_openBlock(), _createElementBlock("article", _hoisted_1, [
        _createVNode(_component_InvalidUrl)
      ]))
    : _createCommentVNode("", true)
}