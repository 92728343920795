import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "about_container" }
const _hoisted_2 = { id: "about_section" }
const _hoisted_3 = { id: "about_header" }
const _hoisted_4 = { id: "about_paragraph" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.t('about-header')), 1),
      _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.t('about-paragraph')), 1)
    ])
  ]))
}