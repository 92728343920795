export default {
  "first-header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play."])},
  "first-header-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest."])},
  "first-header-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Win."])},
  "first-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invested is a gamified mobile app to learn stock investing."])},
  "available-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download SijoittajaApp here"])},
  "sijoittajaApp-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SijoittajaApp will replace Invested 26.10.2022."])},
  "media-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the media"])},
  "second-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invest with play money"])},
  "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With Invested, users can invest to real stocks using play money."])},
  "third-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compete against your friends"])},
  "third-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users can compete with other players on leagues and win amazing prizes!"])},
  "fourth-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn with tutorials and quizzes!"])},
  "fourth-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A comprehensive tutorial with case studies helps you to improve your skills."])},
  "about-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
  "about-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invested Group Oy is a Finnish fintech startup. Our mission is to create fintech software that is easy and fun to use."])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
  "teachers-guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher's guide"])},
  "download-pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download pdf"])},
  "teachers-guide-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download teachers guide pdf from this link"])},
  "to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back to home"])}
}