
import portfolio from "@/assets/app_views/portfolio.svg";
import { defineComponent, ref } from "vue";
import { useI18n } from 'vue-i18n'
export default defineComponent({
  setup() {
    const loaded = ref(false);
    const { t } = useI18n();
    return {
      t,
      loaded,
      portfolio,
    };
  },
});
