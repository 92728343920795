
import { defineComponent } from "vue";
import FirstArticle from "@/components/FirstArticle.vue";
import SecondArticle from "@/components/SecondArticle.vue";
import ThirdArticle from "@/components/ThirdArticle.vue";
import FourthArticle from "@/components/FourthArticle.vue";
import FifthArticle from "@/components/FifthArticle.vue";
export default defineComponent({
  components: {
    FirstArticle,
    SecondArticle,
    ThirdArticle,
    FourthArticle,
    FifthArticle,
  },
});
