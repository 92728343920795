
import { defineComponent } from "vue";
import leverage_eu from "@/assets/brand/leverage_eu.svg";
import logo from "@/assets/brand/logo.svg";
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: "FooterComponent",
  setup() {
    const { t } = useI18n()
    return {
      leverage_eu,
      logo,
      t
    };
  },
});
