import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "join_invested" }
const _hoisted_2 = { class: "section join_invested" }
const _hoisted_3 = { class: "join_invested h4" }
const _hoisted_4 = { id: "joininvested" }
const _hoisted_5 = {
  class: "invested_button",
  "aria-label": "join invested on android"
}
const _hoisted_6 = {
  href: "https://play.google.com/store/apps/details?id=fi.sijoittaja.app",
  target: "_blank"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  class: "invested_button",
  "aria-label": "join invested on ios"
}
const _hoisted_9 = {
  href: "https://apps.apple.com/fi/app/sijoittajaapp/id1543707981?platform=iphone",
  target: "_blank"
}
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "section invested_in_media" }
const _hoisted_12 = { class: "join_invested h4" }
const _hoisted_13 = { id: "in-media" }
const _hoisted_14 = {
  class: "invested_button",
  "aria-label": "invested in suomen osakesäästäjät media"
}
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  class: "invested_button",
  "aria-label": "invested in salkunrakentaja media"
}
const _hoisted_17 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.t('available-header')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", _hoisted_5, [
          _createElementVNode("a", _hoisted_6, [
            _createElementVNode("img", {
              class: "beta_image playstore",
              src: _ctx.playstore
            }, null, 8, _hoisted_7)
          ])
        ]),
        _createElementVNode("button", _hoisted_8, [
          _createElementVNode("a", _hoisted_9, [
            _createElementVNode("img", {
              class: "beta_image appstore",
              src: _ctx.appstore
            }, null, 8, _hoisted_10)
          ])
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_11, [
      _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.t('media-header')), 1),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", _hoisted_14, [
          _createElementVNode("img", {
            class: "media_image viisasraha",
            src: _ctx.viisasraha
          }, null, 8, _hoisted_15)
        ]),
        _createElementVNode("button", _hoisted_16, [
          _createElementVNode("img", {
            class: "media_image salkunrakentaja",
            src: _ctx.salkunrakentaja
          }, null, 8, _hoisted_17)
        ])
      ])
    ])
  ]))
}