import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-408212cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "article" }
const _hoisted_3 = { class: "join" }
const _hoisted_4 = { class: "article third" }
const _hoisted_5 = { class: "article fourth" }
const _hoisted_6 = { class: "article fifth" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FirstArticle = _resolveComponent("FirstArticle")!
  const _component_SecondArticle = _resolveComponent("SecondArticle")!
  const _component_ThirdArticle = _resolveComponent("ThirdArticle")!
  const _component_FourthArticle = _resolveComponent("FourthArticle")!
  const _component_FifthArticle = _resolveComponent("FifthArticle")!

  return (_ctx.$route.path === '/home')
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("article", _hoisted_2, [
          _createVNode(_component_FirstArticle)
        ]),
        _createElementVNode("article", _hoisted_3, [
          _createVNode(_component_SecondArticle)
        ]),
        _createElementVNode("article", _hoisted_4, [
          _createVNode(_component_ThirdArticle)
        ]),
        _createElementVNode("article", _hoisted_5, [
          _createVNode(_component_FourthArticle)
        ]),
        _createElementVNode("article", _hoisted_6, [
          _createVNode(_component_FifthArticle)
        ])
      ]))
    : _createCommentVNode("", true)
}