<template>
  <section>
    <article id="pdf-article">
      <object
        data="/pdf/invested_opeopas.pdf"
        type="application/pdf"
        id="pdf-object"
      >
        <iframe src="/pdf/invested_opeopas.pdf" id="pdf-frame">
          <a href="/pdf/invested_opeopas.pdf" download>Download pdf</a>
        </iframe>
      </object>
      <article>
        <a
          href="/pdf/invested_opeopas.pdf"
          id="download-teachers-guide"
          download
          >{{ t("teachers-guide-download") }}</a
        >
      </article>
    </article>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "teachersGuide",
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>

<style>
#pdf-article {
  border-radius: 0.5em;
  margin: 2.5em auto;
  height: 75vh;
  max-width: 100%;
}
#pdf-object {
  width: 100%;
  max-width: 100%;
  height: 75vh;
  padding: 1em;
  margin: 0 auto .5em;
  border: none;
  border-radius: 0.5em;
}
#pdf-frame::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5em;
}
#pdf-frame::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 0 0.5em 0 0;
}
#pdf-frame::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 0.5em;
}
#download-teachers-guide {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.5em;
  padding: 0.5em;
  font-size: .9em;
  font-weight: 600;
}
</style>