
import { defineComponent } from "vue";
import playstore from "@/assets/misc/playstore.svg"
import appstore from "@/assets/misc/appstore.svg"
import viisasraha from "@/assets/misc/viisasraha.png"
import salkunrakentaja from "@/assets/misc/salkunrakentaja.png"
import { useI18n } from 'vue-i18n'
export default defineComponent({
  data() {
    const { t } = useI18n();
    return {
      t,
      playstore,
      appstore,
      viisasraha,
      salkunrakentaja
    };
  },
  methods: {
    showTermAndConditions() {
      this.$emit("togglePrivacy");
    },
  },
});
