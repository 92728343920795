import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e47b7d6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "article second" }
const _hoisted_2 = { class: "section second" }
const _hoisted_3 = { class: "header h3" }
const _hoisted_4 = { class: "second paragraph" }
const _hoisted_5 = { class: "p first" }
const _hoisted_6 = { class: "appview second container" }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.t("third-header")), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t("third-paragraph")), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_6, [
      _createElementVNode("img", {
        alt: "invested app challenges view",
        class: "second appview",
        src: _ctx.community
      }, null, 8, _hoisted_7)
    ])
  ]))
}