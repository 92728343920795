
import dashboard from "@/assets/app_views/dashboard.svg";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "FirstArticleView",
  setup() {
    const { t } = useI18n();
    const loaded = ref(false);
    return {
      dashboard,
      loaded,
      t,
    };
  },
});
