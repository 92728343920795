import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "article second" }
const _hoisted_2 = { class: "appview second container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "section second" }
const _hoisted_5 = { class: "header h3" }
const _hoisted_6 = { class: "second paragraph" }
const _hoisted_7 = { class: "p first" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("img", {
        alt: "invested app challenges view",
        class: "second appview",
        src: _ctx.study
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.t("fourth-header")), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t("fourth-paragraph")), 1)
      ])
    ])
  ]))
}