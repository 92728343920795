
import logo from "@/assets/brand/logo.svg";
import { defineComponent, ref } from "vue";
import FooterComponent from "@/components/FooterComponent.vue";
import { useI18n } from "vue-i18n";
export default defineComponent({
  components: { FooterComponent },
  setup() {
    const { t, locale } = useI18n();
    function setLocale(lang: string) {
      locale.value = lang;
    }
    const selected_locale = ref("");
    return {
      logo,
      t,
      locale,
      setLocale,
      selected_locale,
    };
  },
});
