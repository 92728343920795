<template>
  <article class="pricvacy-policy article">
    <h1>Invested Group Privacy Policy</h1>
    <p>
      This privacy policy will explain how our organization uses the personal
      data we collect from you when you use our website and/or application.
    </p>
    <p>Topics:</p>
    <ul>
      <li>What data do we collect?</li>
      <li>How do we collect your data?</li>
      <li>How will we use your data?</li>
      <li>How do we store your data?</li>
      <li>What are your data protection rights?</li>
      <li>What are cookies?</li>
      <li>How do we use cookies?</li>
      <li>What types of cookies do we use?</li>
      <li>How to manage your cookies</li>
      <li>Privacy policies of other websites</li>
      <li>Changes to our privacy policy</li>
      <li>How to contact us</li>
      <li>How to contact the appropriate authorities</li>
    </ul>
    <h2>What data do we collect?</h2>
    <p>Our Company collects the following data:</p>
    <ul>
      <li>Email address</li>
      <li>Details of your usage of the app, e.g., play money transactions you have made and your portfolio contents</li>
    </ul>
    <h2>How do we collect your data?</h2>
    <p>
      You directly provide Our Company with most of the data we collect. We
      collect data and process data when you:
    </p>
    <ul>
      <li>Voluntarily complete a form</li>
      <li>Register an account with us (e.g., on Invested application)</li>
      <li>Send an email to us</li>
      <li>Use our application</li>
    </ul>
    <h2>How will we use your data?</h2>
    <p>Our Company collects your data so that we can:</p>
    <ul>
      <li>
        Email you with info about our products and services we think you might
        like.
      </li>
      <li>Manage your account</li>
      <li>Improve our applications and services</li>
      <li>Publish the results of the competitions, including the contents of the portfolios of the users, and use them in marketing</li>
    </ul>
    <h2>How do we store your data?</h2>
    <p>Our Company securely stores your data.</p>
    <p>
      Our Company will keep your data for five years. Once this time period has
      expired, we will delete your data.
    </p>
    <h2>What are your data protection rights?</h2>
    <p>
      Our Company would like to make sure you are fully aware of all of your
      data protection rights. Every user is entitled to the following:
    </p>
    <p class="pricvacy-policy bold">
      The right to access<span>
        – You have the right to request Our Company for copies of your personal
        data. We may charge you a small fee for this service.</span
      >
    </p>
    <p class="pricvacy-policy bold">
      The right to rectification<span>
        – You have the right to request that Our Company correct any information
        you believe is inaccurate. You also have the right to request Our
        Company to complete the information you believe is incomplete.</span
      >
    </p>
    <p class="pricvacy-policy bold">
      The right to erasure<span>
        – You have the right to request that Our Company erase your personal
        data, under certain conditions.
      </span>
    </p>
    <p class="pricvacy-policy bold">
      The right to restrict processing<span>
        – You have the right to request that Our Company restrict the processing
        of your personal data, under certain conditions.</span
      >
    </p>
    <p class="pricvacy-policy bold">
      The right to object to processing<span>
        – You have the right to object to Our Company’s processing of your
        personal data, under certain conditions.</span
      >
    </p>
    <p class="pricvacy-policy bold">
      The right to data portability<span>
        – You have the right to request that Our Company transfer the data that
        we have collected to another organization, or directly to you, under
        certain conditions.</span
      >
    </p>
    <p>
      If you make a request, we have one month to respond to you. If you would
      like to exercise any of these rights, please contact us at our email:
    </p>
    <p>Email to us: info@invested.fi</p>
    <h2>Cookies</h2>
    <p>
      Cookies are text files placed on your computer to collect standard
      Internet log information and visitor behavior information. When you visit
      our websites, we may collect information from you automatically through
      cookies or similar technology.
    </p>
    <p>
      For further information, visit
      <a href="https://www.allaboutcookies.org" target="_blank"
        >allaboutcookies.org</a
      >.
    </p>
    <h2>How do we use cookies?</h2>
    <p>
      Our Company uses cookies in a range of ways to improve your experience on
      our website, including:
    </p>
    <ul>
      <li>Understanding how you use our website</li>
    </ul>
    <h2>What types of cookies do we use?</h2>
    <p>
      There are a number of different types of cookies, however, our website
      uses:
    </p>
    <ul>
      <li>
        Functionality – Our Company uses these cookies so that we recognize you
        on our website and remember your previously selected preferences. These
        could include what language you prefer and location you are in. A mix of
        first-party and third-party cookies are used.
      </li>
    </ul>
    <h2>How to manage cookies</h2>
    <p>
      You can set your browser not to accept cookies, and the above website
      tells you how to remove cookies from your browser. However, in a few
      cases, some of our website features may not function as a result.
    </p>
    <h2>Guardian Consent</h2>
    <p>
      If you are younger than 16 years old we require consent of your guardian
      for the purposes of ensuring safety of minors. By default we consider that
      you have needed consent and by agreeing with our terms and conditions you
      are above 16 years old or hold the consent from the guardian. In case
      suspected that the user is under 16 years old we might ask to provide the
      guardian consent.
    </p>
    <h2>Privacy policies of other websites</h2>
    <p>
      The Our Company website contains links to other websites. Our privacy
      policy applies only to our website, so if you click on a link to another
      website, you should read their privacy policy.
    </p>
    <h2>Changes to our privacy policy</h2>
    <p>
      Our Company keeps its privacy policy under regular review and places any
      updates on this web page. This privacy policy was last updated on 28 June 2022.
    </p>
    <h2>How to contact us</h2>
    <p>
      If you have any questions about Our Company’s privacy policy, the data we
      hold on you, or you would like to exercise one of your data protection
      rights, please do not hesitate to contact us.
    </p>
    <p>Email us at: info@invested.fi</p>
    <h2>How to contact the appropriate authority</h2>
    <p>
      Should you wish to report a complaint or if you feel that Our Company has
      not addressed your concern in a satisfactory manner, you may contact the
      Finnish Data Protection Ombudsman Office.
    </p>
    <p class="pricvacy-policy authorities">tietosuoja@om.fi</p>
    <p class="pricvacy-policy authorities">
      Office of the Data Protection Ombudsman
    </p>
    <p class="pricvacy-policy authorities">Lintulahdenkuja 4</p>
    <p class="pricvacy-policy authorities">00530 Helsinki | Finland</p>
    <p class="pricvacy-policy authorities">
      More information from tietosuoja.fi/en.
    </p>
  </article>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pricvacy-policy.article {
  background: var(--privacy-policy-background-color);
  height: 75vh;
  padding: 1em;
  margin: 2.5em 2em;
  border-radius: .5em;
  overflow-y: scroll;
  font-size: .85em;
}
.pricvacy-policy.article::-webkit-scrollbar {
  background-color: rgba(0,0,0,.5);
  border-radius: .5em;
  height: .25em;
  width: .25em;
}
.pricvacy-policy.article::-webkit-scrollbar-thumb {
  background-color:white;
  border-radius: 0 .5em 0 0;
}
.pricvacy-policy.article::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: .5em;
}
.pricvacy-policy.p {
  max-width: 90%;
  margin: 0 auto;
  text-align: left;
}
a,
button {
  cursor: pointer;
}
h2 {
  text-align: left;
  margin-inline-start: 0.75em;
  margin-bottom: 0.1em;
}
ul {
  max-width: 80%;
  margin-block-start: 0;
  padding-inline-start: 0;
  margin: 0 auto;
}
li {
  text-align: left;
}
li:first-child {
  margin-top: 1em;
}
.bold {
  font-weight: 700;
  margin: 1em auto;
}
span {
  display: inline;
  font-weight: 300;
}
p.privacy-policy.authorities {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
#bottom-exit {
  color: var(--main-font-color);
  background-color: var(--main-background-color);
  border-radius: 2em;
  border: 0.1em solid var(--main-font-color);
  padding: 0.3em 0.75em;
  margin: 1em;
  font-size: 1em;
}
</style>
