
import { defineComponent, ref } from "vue";
import CEO from "@/assets/brand/CEO.svg";
import CFO from "@/assets/brand/CFO.svg";

export default defineComponent({
  setup() {
    const loadedArray = ref([false, false]);
    const loaded = (id: number) => {
      return (loadedArray.value[id] = Boolean(true));
    };
    return {
      loaded,
      CEO,
      CFO,
      loadedArray,
    };
  },
});
