export default {
  "first-header-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelaa."])},
  "first-header-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijoita."])},
  "first-header-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voita."])},
  "first-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invested on pelillistetty osakesijoittamisen oppimissovellus."])},
  "available-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa SijoittajaApp"])},
  "sijoittajaApp-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invested on 26.10.22 alkaen SijoittajaApp."])},
  "media-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediassa"])},
  "second-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sijoita leikkirahan avulla"])},
  "second-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investedissä käyttäjät voivat sijoittaa oikeisiin osakkeisiin käyttämällä leikkirahaa. Tutustu osakemarkkinoihin riskeeraamatta omaa pääomaa!"])},
  "third-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilpaile kavereitasi vastaan"])},
  "third-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttäjät voivat kilpailla liigoissa toisia käyttäjiä vastaan ja voittaa palkintoja, mm. oikeaa rahaa!"])},
  "fourth-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opi tutoriaalien avulla"])},
  "fourth-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paranna taitojasi sijoittajana sovelluksesta löytyvien tutoriaalien avulla"])},
  "about-header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meistä"])},
  "about-paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invested Group Oy on suomalainen fintech-alan startup. Tavoitteemme on tehdä sovelluksia, joita on helppoa ja mukava käyttää."])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yksityisyys"])},
  "teachers-guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opettajan opas"])},
  "download-pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa pdf"])},
  "teachers-guide-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa opettajan opas pdf tästä linkistä"])},
  "to-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["takaisin etusivulle"])}
}