import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import fi from './locales/fi.json'

type MessageSchema = typeof en

function getLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false }
  const opt = { ...defaultOptions, ...options }
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language
  if (!navigatorLocale) {
    return undefined
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim()
  return trimmedLocale
}

const availableLocales = {
  en: "English",
  fi: "Suomi"
}

function getInitialLocale() {
  const browserLocale = getLocale({ countryCodeOnly: true })
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale
  } else {
    return "fi"
  }
}

export default createI18n<[MessageSchema], 'fi' | 'en'>({
  legacy: false,
  locale: getInitialLocale(),
  fallbackLocale: 'fi',
  messages: {
    'fi': fi,
    'en': en
  }
})

export function supportedLocalesInclude(locale: any) {
  return Object.keys(availableLocales).includes(locale)
}