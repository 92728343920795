import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import home from '@/views/MainView.vue'
import about from '@/views/AboutView.vue'
import privacy from '@/views/PrivacyView.vue'
import Teachers from '@/views/TeachersView.vue'
import NotFound from '@/views/NotFound.vue'
import PageNotFoundRedirect from '@/views/PageNotFoundRedirect.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: home
    },
    {
        path: '/about',
        component: about
    },
    {
        path: '/privacy',
        component: privacy
    },
    {
        path: '/teachers',
        component: Teachers
    },
    {
        path: '/opettajat',
        component: Teachers
    },
    { path: "/404", component: NotFound },
    { path: "/:pathMatch(.*)*", component: PageNotFoundRedirect }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router