
import { defineComponent } from "vue";
import AboutUs from "@/components/AboutUs.vue";
import AboutUsPartners from "@/components/AboutUsPartners.vue";
export default defineComponent({
  name: "AboutView",
  components: {
    AboutUs,
    AboutUsPartners,
  },
});
