import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2578e972"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "about"
}
const _hoisted_2 = { class: "about_article" }
const _hoisted_3 = { class: "about_article partners" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AboutUs = _resolveComponent("AboutUs")!
  const _component_AboutUsPartners = _resolveComponent("AboutUsPartners")!

  return (_ctx.$route.path === '/about')
    ? (_openBlock(), _createElementBlock("article", _hoisted_1, [
        _createElementVNode("article", _hoisted_2, [
          _createVNode(_component_AboutUs)
        ]),
        _createElementVNode("article", _hoisted_3, [
          _createVNode(_component_AboutUsPartners)
        ])
      ]))
    : _createCommentVNode("", true)
}